import React from 'react';

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="text-center">
                    <p>
                        Copyright &copy 2022. Template by Minh Nghia. Development by NgThuc. All Rights Reserved.
                        <br /><a href="http://mail.ngthuc.com">Webmail</a> * <a href="https://ngthuc.com/photo-editor">Photo
                            Editor</a> * <a href="https://pixlr.com/editor/">Pixlr</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
import React from 'react';
import './App.css';
import HomeScreen from "./screens/HomeScreen";

// const PageNotFound = () => (
//     <div className="center-screen page-not-found">
//         404 | Page Not Found
//     </div>
// );

const App = () => {
    return (
        <HomeScreen />
    );
}

export default App;
